












import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import Faq from '../../../views/Faq.vue';
import { WPPost, WPCategories } from '../../../../types/typescript-axios/api';
import InfoBanner from './InfoBanner.vue';
import InfoList from './InfoList.vue';

@Component({
  components: {
    InfoBanner,
    InfoList,
  },
})
export default class InfoArea extends Vue {
  @Prop({default: ''})
  private contents!: WPPost[];
  @Prop({default: ''})
  private banners!: WPPost[];
  get infoUrl(): string {
    return process.env.VUE_APP_PATH_INFO;
  }
}
