



import { Component, Vue, Emit } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import InfoArea from '../../components/contents/info/InfoArea.vue';
import Breadcrumb from '../../components/contents/breadcrumb/Breadcrumb.vue';
import Error from '../../components/contents/Error.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getContentsData(url: string, params: any, headers: any) {
  const res = await new Promise((resolve, reject) => {
    axios.get<AxiosResponse>(url, {
      params,
      headers,
    })
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

async function getDatas(to: Route) {
  let status: number = 200;
  const bannerMaxLength: number = 4;
  let url: string = '/wp-json/wp/v2/info';
  const bannerUrl: string = '/wp-json/wp/v2/banner?per_page=' + bannerMaxLength;
  const categoryUrl: string = '/wp-json/wp/v2/info_cat';

  const bannerRes = await axios.get<AxiosResponse>(bannerUrl);
  const bannersRes: any = bannerRes.data;
  const categoryRes = await axios.get<AxiosResponse>(categoryUrl);
  const categoriesRes: any = categoryRes.data;
  const $to: any = to;

  const aryCheck = categoriesRes.filter((value: any) => {
    if (value.slug === $to.query.category && value.count > 0 && value.parent === 0) {
      return true;
    }
  });
  const categories = categoriesRes.filter((value: any) => {
    if (value.count > 0 && value.parent === 0) {
      return true;
    }
  });
  const banners = bannersRes;

  let queryName: string = '';

  if (typeof $to.query.category !== 'undefined') {
    if (aryCheck.length <= 0) {
      return {
        status: 404,
      };
    } else {
      queryName = $to.query.category;
    }
  }

  let params: any = {
    slug: $to.params.slug,
  };
  let headers: any = {};
  if ($to.query.preview) {
    url = `${url}/${$to.query.id}/revisions`;
    params = {
      status: 'auto-draft',
    };
    headers = { 'X-WP-Nonce': window.WP_API_Settings.nonce };
  }
  try {
    const res: any = await getContentsData(url, params, headers);
    status = res.status;
    if (res.data.length > 0) {
      const articleData = res.data[0];

      return {
        status,
        articleData,
        categories,
        banners,
        queryName,
      };
    } else {
      return {
        status: 404,
      };
    }
  } catch (e) {
    return {
      status: e.response.status,
    };
  }
}

@Component({
  components: {
    InfoArea,
    Breadcrumb,
    Error,
  },
})
export default class NewsCoupon extends Vue {
  private title: any = '';
  private paramsName: any = '';
  private article: WPPost|null = null;
  private banners: WPPost[] = [];
  private etcData: any = [];
  private categories: WPCategories[] = [];
  private status = 200;
  private queryName: string = '';
  private categoryId: number = -1;
  get infoUrl(): string {
    return process.env.VUE_APP_PATH_INFO;
  }
  get breadcrumb(): BreadcrumbArr {
    if (this.article) {
      const title: any = this.article.title;
      return [
        {
          title: 'HOME',
          link: '/',
        },
        {
          title: 'お知らせ・キャンペーン一覧',
          link: this.infoUrl,
        },
        {
          title: title.rendered,
          link: '',
        },
      ];
    } else {
      return [];
    }
  }

  public async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    const titleData: any = data.articleData;
    if (data.status === 200) {
      const $to: any = to;
      store.commit(
        'window/setTitle',
        titleData.title.rendered + ' お知らせ・キャンペーン ' + process.env.VUE_APP_TITLE_SUFFIX,
      );
      next((vm: {
        title: any,
        article: WPPost,
        banners: WPPost,
        categories: WPCategories,
        queryName: string,
        paramsName: number,
        categoryId: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.article = data.articleData;
        vm.banners = data.banners;
        vm.categories = data.categories;
        vm.queryName = data.queryName;
        vm.paramsName = Number($to.params.p);
        vm.categoryId = data.categoryId;
        vm.title = titleData.title.rendered;
      });
    } else {
      store.commit(
        'window/setTitle',
        data.status + ' Error お知らせ・キャンペーン ' + process.env.VUE_APP_TITLE_SUFFIX,
      );
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }

  public async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    const titleData: any = data.articleData;
    this.status = data.status;
    if (data.status === 200) {
      store.commit(
        'window/setTitle',
        titleData.title.rendered + ' お知らせ・キャンペーン ' + process.env.VUE_APP_TITLE_SUFFIX,
      );
      const $to: any = to;
      this.paramsName = Number($to.params.p);
      this.article = data.articleData;
      // this.etcData = data.etcData.data;
      this.categories = data.categories;
      this.queryName = data.queryName;
      this.categoryId = data.categoryId;
      this.title = titleData.title.rendered;
    } else {
      store.commit(
        'window/setTitle',
        data.status + ' Error お知らせ・キャンペーン ' + process.env.VUE_APP_TITLE_SUFFIX,
      );
    }
    next();
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
  }

  private async mounted() {
    const item: any = this.article;
    let params = {};
    if (item.categories.length > 0) {
      params = {
        info_cat: item.categories[0].term_id,
      };
    }
    const url: string = '/wp-json/wp/v2/info';
    const data = await axios.get<AxiosResponse>(url, {
      params,
    });
    this.etcData = data.data;
  }
}
