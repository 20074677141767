









































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import Faq from '../../../views/Faq.vue';
import { WPPost, WPCategories } from '../../../../types/typescript-axios/api';
import LabelNew from '@/components/contents/label/LabelNew.vue';

@Component({
  components: {
    LabelNew,
  },
})
export default class InfoList extends Vue {
  @Prop({default: []})
  private contents!: WPPost[];
  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }
  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }
  @Emit()
  private replayDate(date: string) {
    const dateArr: RegExpMatchArray | null = date.match(/^(\d+)-(\d+)-(\d+)/);
    if (dateArr) {
      const newDate: Date = new Date(Number(dateArr[1]), Number(dateArr[2]) - 1, Number(dateArr[3]));
      return newDate;
    } else {
      return null;
    }
  }
}
